import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
// Import your page components here

import HomePage from './pages/Home'
import FormPage from './pages/Form'
import ContactPage from './pages/Contact'


const AppRouter: React.FC = () => {
  return (
    <Router>
      <Navbar />
      <Routes>

        <Route path="/" element={<HomePage/>} />
        <Route path="/Form" element={<FormPage />} />
        <Route path="/contact" element={<ContactPage />} />
    
      </Routes>
    </Router>
  );
};

export default AppRouter;
