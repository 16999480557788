/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { FileUpload, FileUploadSelectEvent } from "primereact/fileupload";
import { ColorPicker } from "primereact/colorpicker";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "tailwindcss/tailwind.css";
import { API } from "../env";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import RollingLoader from "../components/Loader";
import { allStates } from "../utils";
// const primaryColors = ["#E6611D", "#FFB19E", "#F5D77A", "#9FC3E6", "#D259C2"];
// const secondaryColors = ["#FF5C5C", "#FF9472", "#FFD56B", "#97E3D5", "#B38CD9"];

// interface ColorPickerProps {
//   colors: string[];
//   selectedColor: string;
//   onSelectColor: (color: string) => void;
// }

// const ColorPicker: React.FC<ColorPickerProps> = ({
//   colors,
//   selectedColor,
//   onSelectColor,
// }) => {
//   return (
//     <div className="flex space-x-2">
//       {colors.map((color) => (
//         <div
//           key={color}
//           className={`w-10 h-10 rounded-full cursor-pointer ${
//             selectedColor === color ? "ring-2 ring-offset-2 ring-gray-500" : ""
//           }`}
//           style={{ backgroundColor: color }}
//           onClick={() => onSelectColor(color)}
//         ></div>
//       ))}
//     </div>
//   );
// };

const FormPage: React.FC = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(() => localStorage.getItem("access_code"));
  const [domainCheck, setDomainCheck] = useState({
    checking: false,
    text: "Check Domain",
  });
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [brandFile, setBrandFile] = useState<File | null>(null);
  const [haveBranding, setHaveBranding] = useState(undefined);
  const logoStyles = [
    { name: "Minimal (Simple design with essential elements.)", code: "minimal" },
    { name: "Vintage (Classic typography and aged textures.)", code: "Vintage" },
    { name: "Text Only (Typography with brand name and no additional symbols or graphics.)", code: "text only" },
  ];

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    state: {name:'',code:''},
    phoneNumber: "",
    location: "",
    gender: "",
    businessName: "",
    businessAddress: "",
    businessPhoneNumber: "",
    business_facebook: "",
    business_twitter: "",
    business_instagram: "",
    sector: "",
    businessDescription: "",
    domain: { name: "", available: false },
    logo: null,
    primaryColor: "",
    secondaryColor: "",
    logoStyle: {name:'',code:''},
    color_1: "",
    color_2: "",
  });

  const items = [
    { label: "Personal Information" },
    { label: "Business Information" },
    { label: "Preferred Domain" },
    { label: "Branding" },
    { label: "Confirmation" },
  ];

  const updateFormData = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const nextStep = async () => {
    setActiveIndex((prevIndex) => prevIndex + 1);
  };

  const prevStep = () => {
    setActiveIndex((prevIndex) => prevIndex - 1);
  };

  const handleLogoFileSelect = (e: FileUploadSelectEvent) => {
    const selectedFile = e.files[0];
    setLogoFile(selectedFile);
  };

  const handleBrandFileSelect = (e: FileUploadSelectEvent) => {
    const selectedFile = e.files[0];
    setBrandFile(selectedFile);
  };

  const submit = async () => {

    const colors = [formData.color_1,formData.color_2].map((e)=>e ? e = `#${e}`: '')
    const form = new FormData();
    form.append("firstname", formData.firstName);
    form.append("lastname", formData.lastName);
    form.append("email", formData.email);
    form.append("state", formData.state.code);
    form.append("mobile_number", formData.phoneNumber);
    form.append("location", formData.location);
    form.append("business_name", formData.businessName);
    form.append("business_address", formData.businessAddress);
    form.append("business_phone_no", formData.businessPhoneNumber);
    form.append("facebook_link", formData.business_facebook);
    form.append("twitter_link", formData.business_twitter);
    form.append("instagram_link", formData.business_instagram);
    form.append("sector", formData.sector);
    form.append("domain_name", formData.domain.name);
    form.append("business_description", formData.businessDescription);
    form.append('color_schemes',JSON.stringify(colors))
    form.append('logo_style',formData.logoStyle.name)

    if (logoFile) {
      form.append("logo", logoFile!);
    }
    if (brandFile) {
      form.append("brand_guideline", brandFile!);
    }
    form.append("access_code", token as string);
    setSubmitLoader(true);
    try {
      const { data, status } = await axios.post(
        `${API}/user/submit-item`,
        form,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (status === 200) {
        setFormData({} as any);
        toast.success("Your response has been saved!", { duration: 10000 });
        navigate("/");
      } else {
        toast.error(data.message, { duration: 6000 });
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      //@ts-ignore
      toast.error(error.response.data.message, { duration: 6000 });
    }
  };

  const verifyDomain = async () => {
    try {
      setDomainCheck({ ...domainCheck, checking: true });
      const { status, data } = await axios.get(
        `${API}/user/check-domain/${formData.domain.name}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (status === 200) {
        if (data.data.status === "available") {
          toast.success("Domain is available!", { duration: 4000 });
          updateFormData("domain", { ...formData.domain, available: true });
          setDomainCheck({ checking: false, text: "Domain Name is Available" });
        } else {
          toast.error(
            "Domain Name is already in use, please try another name.",
            { duration: 5000 }
          );
          setDomainCheck({ ...domainCheck, checking: false });
        }
      } else {
        setDomainCheck({ ...domainCheck, checking: false });
        toast.error("Error verifying domain", { duration: 5000 });
      }
    } catch (error) {
      setDomainCheck({ ...domainCheck, checking: false });
      //@ts-ignore
      toast.error(error.response.data.message, { duration: 7000 });
    }
  };

  useEffect(() => {
    (() => {
      if (!token) {
        navigate("/");
      }
    })();
  }, []);

  const renderStepContent = () => {
    switch (activeIndex) {
      case 0:
        return (
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Personal Information</h2>
            <div className="grid grid-cols-2 gap-4 ml-1">
              <div className="mb-4 w-[50%]">
                <label htmlFor="firstName" className="block mb-2">
                  First Name
                </label>
                <InputText
                  placeholder="John"
                  id="firstName"
                  value={formData.firstName}
                  onChange={(e: { target: { value: any } }) =>
                    updateFormData("firstName", e.target.value)
                  }
                  className="w-full"
                />
              </div>
              <div className="mb-4 w-[40%]">
                <label htmlFor="lastName" className="block mb-2">
                  Last Name
                </label>
                <InputText
                  placeholder="Doe"
                  id="lastName"
                  value={formData.lastName}
                  onChange={(e: { target: { value: any } }) =>
                    updateFormData("lastName", e.target.value)
                  }
                  className="w-full"
                />
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block mb-2">
                Email
              </label>
              <InputText
                id="email"
                placeholder="email@gmail.com"
                value={formData.email}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("email", e.target.value)
                }
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="phoneNumber" className="block mb-2">
                Phone Number
              </label>
              <InputText
                type="tel"
                placeholder="+2340000000000"
                id="phoneNumber"
                value={formData.phoneNumber}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("phoneNumber", e.target.value)
                }
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="location" className="block mb-2">
                Location
              </label>
              <InputText
                id="location"
                value={formData.location}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("location", e.target.value)
                }
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="state" className="block mb-2">
                State Of Origin
              </label>
              <Dropdown
                    value={formData.state}
                    onChange={(e) => updateFormData("state", e.value)}
                    options={allStates()}
                    optionLabel="name"
                    // placeholder="State Of Origin"
                    className="w-full bg-[#eeee] mt-3 py-2"
                  />
              {/* <InputText
                id="state"
                value={formData.state}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("state", e.target.value)
                }
                className="w-full"
              /> */}
            </div>
          </div>
        );
      case 1:
        return (
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Business Information</h2>
            <div className="mb-4">
              <label htmlFor="businessName" className="block mb-2">
                Business Name
              </label>
              <InputText
                id="businessName"
                value={formData.businessName}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("businessName", e.target.value)
                }
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="businessAddress" className="block mb-2">
                Business Address
              </label>
              <InputText
                id="businessAddress"
                value={formData.businessAddress}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("businessAddress", e.target.value)
                }
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="businessPhoneNumber" className="block mb-2">
                Business Phone Number
              </label>
              <InputText
                type="tel"
                placeholder="+2340000000000"
                id="businessPhoneNumber"
                value={formData.businessPhoneNumber}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("businessPhoneNumber", e.target.value)
                }
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="businessSocials" className="block mb-2">
                Business Facebook Link (optional)
              </label>
              <InputText
                placeholder="https://facebook.com"
                id="businessSocials"
                value={formData.business_facebook}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("business_facebook", e.target.value)
                }
                className="w-full"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="businessSocials" className="block mb-2">
                Business Twitter Link (optional)
              </label>
              <InputText
                id="businessSocials"
                value={formData.business_twitter}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("business_twitter", e.target.value)
                }
                className="w-full"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="businessSocials" className="block mb-2">
                Business Instagram Link (optional)
              </label>
              <InputText
                id="businessSocials"
                value={formData.business_instagram}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("business_instagram", e.target.value)
                }
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="sector" className="block mb-2">
                Sector/Industry
              </label>
              <InputText
                id="sector"
                value={formData.sector}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("sector", e.target.value)
                }
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="businessDescription" className="block mb-2">
                Business Description
              </label>
              <InputText
                id="businessDescription"
                value={formData.businessDescription}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("businessDescription", e.target.value)
                }
                className="w-full"
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Preferred Domain</h2>
            <div className="mb-4">
              <label htmlFor="domain" className="block mb-2">
                Domain Name
              </label>
              <InputText
                placeholder="mybusiness.com.ng"
                id="domain"
                value={formData.domain.name}
                onChange={(e: { target: { value: any } }) =>
                  updateFormData("domain", {
                    name: e.target.value,
                    available: false,
                  })
                }
                className="w-full"
              />
            </div>
            {formData.domain.name && (
              <button
                onClick={verifyDomain}
                className={`${
                  formData.domain.available
                    ? "bg-[#63d063] px-4 py-2 text-[#030303]"
                    : "bg-[#E2E2E2] px-4 py-2 text-[#23272b]"
                } rounded-md`}
              >
                {domainCheck.checking ? (
                  <RollingLoader width={18} height={18} color="#D50036" />
                ) : (
                  <span>{domainCheck.text}</span>
                )}
              </button>
            )}
          </div>
        );
      case 3:
        return (
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">
              Do you have a brand guideline ?
            </h2>

            <div className="flex flex-row items-center">
              <input
                onChange={() => setHaveBranding("Yes" as any)}
                type="radio"
                name="haveYourBranding"
                value={"Yes"}
                defaultChecked={haveBranding === 'Yes'}
                className="size-5"
              ></input>
              <span className="ml-4">Yes</span>
            </div>

            <div className="mt-3 flex flex-row items-center">
              <input
                onChange={() => setHaveBranding("No" as any)}
                type="radio"
                name="haveYourBranding"
                value={"No"}
                defaultChecked={haveBranding === 'No'}
                className="size-5"
              ></input>
              <span className="ml-4">No</span>
            </div>

            {haveBranding === "Yes" && (
              <div>
                <div className="mt-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4">
                  <p className="font-medium">
                    <strong>NOTE:&nbsp;</strong>Your uploads are pending until after preview. All records are submitted at once.
                  </p>
                </div>
                <div className="mt-3">
                  <label htmlFor="logo" className="block mb-2">
                    Business Logo (PNG/SVG)
                  </label>
                  <FileUpload
                    name="logo"
                    accept="image/png;image/jpg;image/svg"
                    onSelect={handleLogoFileSelect}
                    customUpload
                    auto
                    className="w-full"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="logo" className="block mb-2">
                    Brand Guidline
                  </label>
                  <FileUpload
                    name="logo"
                    onSelect={handleBrandFileSelect}
                    customUpload
                    auto
                    className="w-full"
                  />
                </div>
              </div>
            )}

            {haveBranding === "No" && (
              <div>
                <div className="card flex flex-col justify-content-center">
                  <label htmlFor="logo" className="block mb-1 mt-3">
                    Preferred Logo Style
                  </label>
                  <Dropdown
                    value={formData.logoStyle}
                    onChange={(e) => updateFormData("logoStyle", e.value)}
                    options={logoStyles}
                    optionLabel="name"
                    placeholder="Select Logo Styles"
                    className="w-full bg-[#eeee] mt-3 py-1"
                  />
                </div>

                <div className="flex flex-col">
                  <div className="mt-3 inline-flex space-x-2 items-center">
                    <span>Preferred Primary Color</span>
                    <ColorPicker
                      value={formData.color_1}
                      onChange={(e) =>
                        updateFormData("color_1", e.value as any)
                      }
                    />
                  </div>

                  <div className="mt-3 inline-flex space-x-2 items-center">
                    <span>Preferred Secondary Color</span>
                    <ColorPicker
                      value={formData.color_2}
                      onChange={(e) =>
                        updateFormData("color_2", e.value as any)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {/* <div className="mb-4">
              <label htmlFor="logo" className="block mb-2">Business Logo (PNG)</label>
              <FileUpload name="logo" accept="image/png" onSelect={handleFileSelect} customUpload auto className="w-full" />
            </div> */}
          </div>
        );
      case 4:
        return (
          <div className="p-4">
            {/* <h2 className="text-2xl font-bold mb-4">Confirmation</h2> */}
            {/* <p>Please review your information before submitting:</p> */}
            <ReviewSummary {...formData} />
            {/* <pre className="bg-gray-100 p-4 rounded">{JSON.stringify(formData, null, 2)}</pre> */}
            <Button
              className="mt-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              onClick={submit}
            >
              {submitLoader ? (
                <RollingLoader color="#fefffe" width={18} height={18} />
              ) : (
                <span>Submit</span>
              )}
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-4">
      <Steps
        model={items}
        activeIndex={activeIndex}
        onSelect={(e: { index: React.SetStateAction<number> }) =>
          setActiveIndex(e.index)
        }
        className="mb-4 "
      />
      <div className="bg-white shadow-sm rounded p-4">
        {renderStepContent()}
        <div className="flex justify-between mt-4">
          <Button
            label="Previous"
            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            disabled={activeIndex === 0}
            onClick={prevStep}
          />
          <Button
            label="Next"
            className="bg-green-500 text-white font-semibold py-2 px-4 rounded-md shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            disabled={
              activeIndex === items.length - 1 ||
              (activeIndex === 2 && !formData.domain.available)
            }
            onClick={nextStep}
          />
        </div>
      </div>
    </div>
  );
};

export default FormPage;

interface Domain {
  name: string;
  available: boolean;
}

interface ReviewSummaryProps {
  firstName: string;
  lastName: string;
  email: string;
  state: {name:string,code:string};
  phoneNumber: string;
  location: string;
  businessName: string;
  businessAddress: string;
  businessPhoneNumber: string;
  business_facebook: string;
  business_twitter: string;
  business_instagram: string;
  sector: string;
  businessDescription: string;
  domain: Domain;
  primaryColor: string;
  secondaryColor: string;
  color_1: string;
  color_2: string;
}

const ReviewSummary: React.FC<ReviewSummaryProps> = (props) => {
  return (
    <div className="p-m-5">
      <Card
        title="Please review your information before submitting:"
        className="p-shadow-5"
      >
        <Panel header="Personal Information" className="p-mb-4">
          <p>
            <strong>First Name:</strong> {props.firstName}
          </p>
          <p>
            <strong>Last Name:</strong> {props.lastName}
          </p>
          <p>
            <strong>Email:</strong> {props.email}
          </p>
          <p>
            <strong>State:</strong> {props.state.code}
          </p>
          <p>
            <strong>Phone Number:</strong> {props.phoneNumber}
          </p>
          <p>
            <strong>Location:</strong> {props.location}
          </p>
        </Panel>

        <Panel header="Business Information" className="p-mb-4">
          <p>
            <strong>Business Name:</strong> {props.businessName}
          </p>
          <p>
            <strong>Business Address:</strong> {props.businessAddress}
          </p>
          <p>
            <strong>Business Phone Number:</strong> {props.businessPhoneNumber}
          </p>
          <p>
            <strong>Business Facebook:</strong> {props.business_facebook}
          </p>
          <p>
            <strong>Business Twitter:</strong> {props.business_twitter}
          </p>
          <p>
            <strong>Business Instagram:</strong> {props.business_instagram}
          </p>
          <p>
            <strong>Sector:</strong> {props.sector}
          </p>
          <p>
            <strong>Business Description:</strong> {props.businessDescription}
          </p>
        </Panel>

        <Panel header="Domain Information" className="p-mb-4">
          <p>
            <strong>Domain Name:</strong> {props.domain.name}
          </p>
        </Panel>

        {
          (props.color_1 !=='' || props.color_2 !=='') && (
            <Panel header="Branding Information" className="p-mb-4">
          <div>
            <strong>Primary Color</strong>&nbsp;<div style={{background:`#${props.color_1}`}} className="max-w-10 h-10"></div>
          </div>

          <div>
            <strong>Secondary Color</strong>&nbsp;<div style={{background:`#${props.color_2}`}} className="max-w-10 h-10"></div>
          </div>
        </Panel>
          )
        }

      </Card>
    </div>
  );
};
