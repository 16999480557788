import React from 'react';
import AppRouter from './Router';
import 'primereact/resources/themes/saga-blue/theme.css';  // Choose your theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";
import './index.css';
import { Toaster } from 'react-hot-toast';
import Footer from './components/Footer';

const App: React.FC = () => {
  return (
    <div className="font-sans">
      <Toaster/>
      <AppRouter />
    </div>
  );
};

export default App;
