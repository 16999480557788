/* eslint-disable jsx-a11y/iframe-has-title */
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import RollingLoader from '../components/Loader';
import { API } from '../env';
import axios from 'axios';
import toast from 'react-hot-toast';
const HomePage: React.FC = () => {
  const [accessModal, setAccessModal] = useState<boolean>(false)
  const [code,setCode] = useState("")
  const [verifyCode,setVerifyCode] = useState(false)
  const navigate = useNavigate();

  const handleNavigation = () => {
    setAccessModal(true)
    //navigate('/form');
  };

  const verifyAccessCode = async() => {
    if(!code){
      toast.error("Invalid input provided",{duration:5000})
      return;
    }
    setVerifyCode(true)
    try {
      const {data} = await axios.get(`${API}/user/verify-access-code/${code}`,{headers:{'Access-Control-Allow-Origin':"*"}})
      if(data.data.status){
        localStorage.setItem('access_code',code)
        navigate('/form')
      }else{
        toast.error('Invalid or used code',{duration:5000})
      }
      setVerifyCode(false)
    } catch (error) {
      //@ts-ignore
      toast.error(error.response.data.message,{duration:5000})
      setVerifyCode(false)
    }
  }
  return (
    <div>

      <Dialog visible={accessModal} className='w-[90vw] md:w-[30vw]' onHide={() => { if (!accessModal) return; setAccessModal(false); }}>
        <div className="p-3 flex flex-col justify-center text-center">
          <span className="block text-4xl font-thin mb-1">Access Code Required</span>
          <p className="mb-4">
            To proceed, please enter your assigned access code.
          </p>
          <div>
          <InputText id="access_code" placeholder='Access Code' value={code} onChange={(e: { target: { value: any; }; }) => setCode(e.target.value)} className="w-full mb-4" />
          </div>
          <div className='flex flex-row justify-center'>
            <button
              className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none min-w-[100px] min-h-[45px]"
              onClick={verifyAccessCode}
            >
              {
                verifyCode ? <RollingLoader color='#fefffe' width={18} height={18} /> : <span>Proceed</span>
              }
            </button>
          </div>
        </div>
      </Dialog>

      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2]">
          <section className=''>
            <span className="block text-6xl font-thin mb-1">1KDP - One Thousand Digital Presence.</span>
            <div className="text-6xl text-red font-thin mb-3"></div>
            <p className="mt-0 mb-4 text-700 line-height-3">This initiative is sponsored by Suburban, 
            in collaboration with the Office of the Vice President and led by the Federal Ministry of Industry, Trade, and Investment , it aims to digitize 1,000 women-led businesses by providing them with domains and websites.</p>
            <span className='font-semibold'>
              Instructions
            </span>
            <p>Please follow the steps below to complete your application:
            </p>
            <br></br>
            <ul className="list-disc pl-5 mb-3">
              <li><b>Personal Information:</b> Provide your basic details including name, email, phone number, and location.</li>
              <li><b>Business Information:</b> Share details about your business.</li>
              <li><b>Preferred Domain:</b> Select your preferred domain name for your website.</li>
              <li><b>Branding:</b> Upload your business logo and choose your preferred colors to personalize your website.</li>
              <li><b>Pathway for Build:</b> Finalize the details and confirm your application.
              </li>

            </ul>


            <Button label="Get Started" iconPos="right" icon="pi pi-arrow-right" type="button" size="large" className="mr-3 p-2 p-button-raise bg-[transparent] text-black border border-[#d50036]  z-10" style={{ color: '#d50036' }} onClick={handleNavigation}></Button>
          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2]">
          <img src="hero.jpg" alt="hero-1" className="md:ml-auto block md:m-h-full object-contain" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
          <img src="FMITI-logo.png" alt="hero-1" className="w-80 opacity-75" />

        </div>
      </div>





    </div>

  );
};

export default HomePage;
