export default function RollingLoader({
    width,
    height,
    color
  }: {
    width: number;
    height?: number;
    color?: string
  }) {
    const loaderStyle = {
      margin: "auto",
      background: "none",
      display: "block",
      shapeRendering: "auto",
    };
    return (
      <>
        <svg
        //@ts-ignore
          style={loaderStyle}
          width={`${width ?? 200}px`}
          height={`${`${height}px` ?? "auto"}`}
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke={color || '#f8f8f8'}
            strokeWidth="10"
            r="35"
            strokeDasharray="164.93361431346415 56.97787143782138"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
      </>
    );
  }
  