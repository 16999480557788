import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigate, useLocation } from 'react-router-dom';
//import MegaMenu from './MegaMenu';
//import { Bars3Icon, ChevronDownIcon } from '@heroicons/react/24/outline';

const Navbar: React.FC = () => {
  const [showMegaMenu, setShowMegaMenu] = useState<string | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const hoverRef = useRef<NodeJS.Timeout | null>(null);

  // const menuItems = [
  //   {
  //     name: 'Virtual Infrastructure Services',
  //     items: [
  //       {
  //         title: 'Compute',
  //         path: '',
  //         icon: '/vm.png',
        
  //         subItems: [
  //           { title: 'Virtual Machine', path: '/compute/virtual-machine', icon: '/vm.png' },
  //           { title: 'Serverless', path: '/compute/serverless', icon: '/serverless.png' },
  //           { title: 'DaaS', path: '/compute/daas', icon: '/daas.png' },
  //         ],
  //       },
  //       { title: 'Virtual Data Center', path: '/virtual-data-center', icon: '/data-center.png' },
  //       { title: 'Storage (FTP)', path: '/storage/ftp', icon: '/storage.png' },
  //     ],
  //   },
  //   {
  //     name: 'Web Services',
  //     items: [
  //       {
  //         title: 'Hosting',
  //         path: '',
  //         icon: '/icons/hosting.png',
  //         subItems: [
  //           { title: 'Shared Hosting', path: '/hosting/shared', icon: '/shared-hosting.png' },
  //           { title: 'VPS Hosting', path: '/hosting/vps', icon: '/vps.png' },
  //         ],
  //       },
  //       { title: 'Email', path: '/email',  icon: '/email.png' },
  //       { title: 'Domains', path: '/domains', icon: '/domains.png' },
  //     ],
  //   },
  //   {
  //     name: 'PaaS',
  //     items: [
  //       { title: 'DBaaS', path: '/dbaas', icon: '/dbaas.png' },
  //     ],
  //   },
  // ];

  useEffect(() => {
    setIsMobileMenuOpen(false);
    setShowMegaMenu(null);
  }, [location]);

  const handleMouseEnter = (menu: string) => {
    if (hoverRef.current) {
      clearTimeout(hoverRef.current);
    }
    setShowMegaMenu(menu);
  };

  const handleMouseLeave = () => {
    if (hoverRef.current) {
      clearTimeout(hoverRef.current);
    }
    hoverRef.current = window.setTimeout(() => {
      setShowMegaMenu(null);
    }, 300) as unknown as NodeJS.Timeout;
  };

  const handleMenuClick = (menu: string) => {
    if (showMegaMenu === menu) {
      setShowMegaMenu(null);
    } else {
      setShowMegaMenu(menu);
    }
  };

  const start = (
    <div className="flex items-center">
      <div className="text-2xl font-bold text-accent cursor-pointer" onClick={() => navigate('/')}>
        <img src="/logo-black-red.svg" alt="logo" className="w-48 sm:w-[60%]" />
      </div>
    </div>
  );

  const end = (
    <Button label="Login" className="p-button-primary px-4 py-1 rounded-sm bg-[#d50036] text-white text-sm" onClick={() => navigate('/login')} />
  );

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="fadein animation-duration-1000 bg-secondary">
      <div className="flex justify-between items-center p-1 px-4 py-0">
        {start}
        <div className="hidden md:flex space-x-6 text-accent text-sm ">
          
        
          </div>
 
      </div>
     
    </nav>
  );
};

export default Navbar;