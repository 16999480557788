import React from 'react';
import { Button } from 'primereact/button';

const GeneralInquiryForm: React.FC = () => {
  return (
    <div className="mb-4 w-[100%]">
      
      <form className="bg-white p-6 rounded border-custom mt-5 pl-4">
      <span className="block text-4xl font-thin mb-2">Contact Us</span>
      <p className="mt-0 mb-4 text-700 line-height-3 tracking-5">At Suburban Cloud Nigeria, we believe in the power of excellence, innovation, and customer-centric solutions to drive digital transformation for businesses of all sizes.</p>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-normal mb-2" htmlFor="generalName">Name</label>
          <input
            type="text"
            id="generalName"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your name"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-normal mb-2" htmlFor="generalEmail">Email</label>
          <input
            type="email"
            id="generalEmail"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your email"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-normal mb-2" htmlFor="generalSubject">Subject</label>
          <input
            type="text"
            id="generalSubject"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Subject"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-normal mb-2" htmlFor="generalMessage">Message</label>
          <textarea
            id="generalMessage"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your message"
          ></textarea>
        </div>
        <Button label="Submit" className="p-button-primary border-button p-2 w-100" />
      </form>
    </div>
  );
};

export default GeneralInquiryForm;